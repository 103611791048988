#video {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 130px 0 100px 0;
	box-sizing: border-box;
	background-color: #000;
	height: #{m}in(#{m}ax(600px, 44vw), 800px);

	@media screen and (max-width: 750px) {
		height: max-content;
		padding: 32px 0;
	}

	&::before {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 122px;
		background-image: linear-gradient(to bottom, rgba(242, 239, 235, 1.0), rgba(242, 239, 235, 0.0));
		content: "";
	}

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 101%;
		opacity: 0.88;
		object-fit: cover;
	}

	.content {
		position: relative;
		z-index: 1;
		max-width: 516px;
		margin: 0 auto;
		margin-bottom: 11px;
		font-size: 97px;
		line-height: 95px;
		font-family: quincy;
		font-weight: 300;
		color: #fff;
		text-align: center;

		@media screen and (max-width: 750px) {
			font-size: 34px;
			line-height: 32px;
			margin-bottom: 16px;
		}

		p {
			margin: 0;
		}
	}

	.play {
		position: relative;
		z-index: 1;
		display: flex;
		width: max-content;
		flex-direction: column;
		margin: 0 auto;
		cursor: pointer;

		&:hover .text {
			text-decoration: underline;
		}
		
		.icon {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border: 2px solid #fff;
			border-radius: 50%;
			width: 113px;
			height: 113px;
			margin-bottom: 18px;

			@media screen and (max-width: 750px) {
				width: 58px;
				height: 58px;
				margin: 0;
			}

			&::after {
				width: 34px;
				height: 43px;
				background-color: #fff;
				clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
				margin-left: 8px;
				content: "";

				@media screen and (max-width: 750px) {
					width: 18px;
					height: 22px;
				}
			}
		}

		.text {
			font-size: 11px;
			font-weight: 500;
			line-height: 14px;
			letter-spacing: 2px;
			margin: 0 auto;
			color: #fff;

			@media screen and (max-width: 750px) {
				display: none;
			}
		}
	}
}

#fullscreen-video--wrapper {
	position: relative;
	width: 100%;
	height: 100%;

	.link {
		position: absolute;
		bottom: 120px;
		left: 50%;
		padding: 8px;
		color: #C1974D;
		font-size: 24px;
		text-decoration: none;
		transform: translateX(-50%);

		&:hover {
			text-decoration: underline;
		}
		
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			background-color: rgba(0, 0, 0, 0.6);
			content: "";
		}
	}
}

#fullscreen-video {
	width: 100%;
	height: 100%;
	border: none;
}