go-episode {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-decoration: none;

	&:not(.released) {
		.thumbnail-wrapper {
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(213, 213, 213, 0.75);
				content: "";
			}
		}
	}

	&.released {
		.thumbnail-wrapper .icon {
			position: absolute;
			display: inline-flex;
			width: 75px;
			height: 75px;
			left: calc(50% - 38.5px);
			top: calc(50% - 38.5px);
			align-items: center;
			justify-content: center;
			border: 2px solid #fff;
			border-radius: 50%;
		}

		.content-wrapper .title {
			text-decoration: none;

			&:hover {
				text-decoration: underline
			}
		}
	}
	
	.thumbnail-wrapper {
		position: relative;
		height: 256px;
		width: 100%;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	.content-wrapper {
		background-color: #fff;
		padding: 28px;
		height: 100%;

		.title {
			display: block;
			margin: 0;
			margin-bottom: 10px;
			font-family: quincy, serif;
			font-size: 24px;
			font-weight: 400;
			line-height: 31px;
			color: #C1974D;
		}

		.content {
			@include default-fonts;
			color: #3C3C3C;
			font-size: 14px;
			margin-bottom: 0;

			p {
				line-height: 21px;
			}
		}

		.link {
			display: inline-block;
			color: #C1974D;
			margin-top: 24px;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}