a.button, button {
	display: inline-block;
	padding: 12px 50px;
	line-height: 24px;
	font-family: quincy, serif;
	font-weight: 600;
	font-size: 16px;
	border-radius: 24px;
	text-align: center;
	text-decoration: none;
	border: none;

	&.outline {
		padding: 11px 49px;
		border: 1.5px solid #C1974D;
		color: #C1974D;
		transition: background-color .2s, color .2s;

		&:hover {
			background-color: #C1974D;
			color: #fff;
		}
	}

	&.solid {
		background-color: #C1974D;
		color: #fff;
		transition: background-color .2s;

		&:hover {
			background-color: #AC8643;
		}
	}
}