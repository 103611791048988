#newsletter {
	position: relative;
	display: flex;
	width: 100%;
	height: 376px;
	box-sizing: border-box;
	padding: 0 36px;
	align-items: center;
	justify-content: center;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.content-wrapper {
		position: relative;
		z-index: 1;

		&.submitted {


			#newsletter-form {
				pointer-events: none;

				input, button {
					opacity: 0;
					transition: opacity .3s;
				}

				&::after {
					opacity: 1;
					transition: opacity .3s;
				}
			}
		}

		h3 {
			text-align: center;
			font-size: 45px;
			font-weight: 500;
			font-family: quincy, serif;
			color: #C1974D;
			margin: 0;
		}

		p {
			text-align: center;
			color: #fff;
			font-size: 18px;
			margin: 9px 0 24px 0;
		}

		#newsletter-form {
			position: relative;
			display: flex;
			justify-content: center;
			max-width: 100%;

			input {
				display: inline-block;
				line-height: 21px;
				font-size: 18px;
				max-width: 330px;
				margin: 0;
				flex: 1;
				color: #3C3C3C;
				padding: 13px 0px 13px 30px;
				border-top-left-radius: 25.5px;
				border-bottom-left-radius: 23.5px;
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				border-top: 2px solid #C1974D;
				border-left: 2px solid #C1974D;
				border-bottom: 2px solid #C1974D;
				border-right: none;

				&::placeholder {
					color: #C6C6C6;
				}

				&:focus {
					outline: none;
				}
			}

			button {
				display: inline-flex;
				height: 51px;
				width: 80px;
				padding: 0;
				border-top-left-radius: 0px;
				border-top-right-radius: 25.5px;
				border-bottom-right-radius: 25.5px;
				border-bottom-left-radius: 0px;
				border: none;
				background-color: #C1974D;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				&:hover, &:focus {
					outline: none;
					
					.stem {
						width: 37.5px;
					}

					.head {
						transform: translate(32px, 2px);
					}
				}

				.stem {
					transform: translate(0px, 6.5px);
					width: 33.5px;
					transition: width .2s;
				}

				.head {
					transform: translate(28px, 2px);
					transition: transform .2s;
				}
			}

			&::after {
				position: absolute;
				opacity: 0;
				pointer-events: none;
				line-height: 24px;
				font-size: 20px;
				top: 13px;
				color: #fff;
				content: "Thank You!";
			}
		}
	}

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(39, 39, 39, 0.7);
		content: "";
	}
}