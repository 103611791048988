a.buzz-post {
	width: 100%;
	display: flex;
	flex-direction: column;
	text-decoration: none;

	&.article {
		img {
			height: 464px;
		}

		.summary .title {
			margin: 0;
		}
	}

	img {
		width: 100%;
		height: 252px;
		object-fit: cover;
	}

	.summary {
		padding: 16px 22px 22px 22px;
		background-color: #fff;
		flex: 1;

		.title {
			color: #C1974D;
			font-family: quincy, serif;
			font-size: 24px;
			line-height: 30px;
			margin: 0;
			margin-bottom: 16px;
		}

		.description {
			margin: 0;
			font-size: 11px;
			line-height: 16px;
			color: #3C3C3C;
		}
	}
}