.recent-buzz {
	padding: 0 92px;
	box-sizing: content-box;
	width: calc(100% - 184px);
	max-width: 1272px;
	margin: 95px auto;

	@media screen and (max-width: 930px) {
		padding: 0 36px;
		width: 100%;
		box-sizing: border-box;
	}

	.heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 17px;

		@media screen and (max-width: 930px) {
			justify-content: space-around;
		}

		h2 {
			font-size: 48px;
			font-family: quincy, serif;
			color: #C1974D;
			font-weight: 400;
			margin: 0;
		}

		a {
			color: #473833;
			text-decoration: none;

			@media screen and (max-width: 930px) {
				display: none;
			}

			&:hover {
				color: #C1974D;
				text-decoration: underline;
			}
		}
	}

	.posts {
		display: flex;
		align-items: stretch;
		
		@media screen and (min-width: 931px) {
			& > a:not(:first-child) {
				margin-left: 16px;
			}
		}

		@media screen and (max-width: 930px) {
			flex-direction: column;
			
			& > a:not(:first-child) {
				margin-top: 16px;
			}
		}
	}

	.mobile-link {
		display: none;
		color: #C1974D;
		text-decoration: underline;
		margin-top: 35px;
		text-align: center;

		@media screen and (max-width: 930px) {
			display: block;
		}
	}
}