section.instagram {
	padding: 75px 0 0 0;
	background-color: #fff;

	.heading {
		font-size: 45px;
		font-weight: 400;
		color: #C1974D;
		font-family: quincy, serif;
		margin: 0;
		margin-bottom: 25px;
		text-align: center;
	}

	.images {
		display: flex;

		.image-wrapper {
			position: relative;
			display: block;
			flex: 1;
			padding-bottom: 25%;

			@media screen and (max-width: 900px) {
				padding-bottom: 50%;
			}

			@media screen and (max-width: 450px) {
				padding-bottom: 100%;
			}

			&:nth-child(3), &:nth-child(4) {
				@media screen and (max-width: 900px) {
					display: none;
				}
			}

			&:nth-child(2) {
				@media screen and (max-width: 450px) {
					display: none;
				}
			}

			&:hover::after {
				background-color: rgba(0, 0, 0, 0.1);
			}

			img, video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&::after {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: background-color .2s;
				background-color: rgba(0, 0, 0, 0);
				content: "";
			}
		}
	}
}