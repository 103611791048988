[animation-appear] {
	&[animation-appear*=fade] {
		opacity: 0;
		transition: opacity 1s;

		&[animation-ready] {
			opacity: 1;
		}
	}

	&[animation-appear*=up] {
		transform: translateY(100px);
		transition: transform 1s;

		&[animation-ready] {
			transform: translateY(0px);
		}
	}

	&[animation-appear*=right] {
		transform: translateX(-100px);
		transition: transform 1s;

		&[animation-ready] {
			transform: translateX(0px);
		}
	}

	&[animation-appear*=up][animation-appear*=fade], &[animation-appear*=right][animation-appear*=fade] {
		transition: transform 1s, opacity 1s;
	}
}

span[animation-appear] {
	display: inline-block;
}

go-image {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&:hover [expand-wrapper] {
		transform: scale(1.1);
	}

	[parallax-wrapper] {
		position: absolute;
		display: block;
		top: calc(var(--parallax-range, 50px) * -1);
		left: calc(var(--parallax-range, 50px) * -1);
		width: calc(100% + var(--parallax-range, 50px) * 2);
		height: calc(100% + var(--parallax-range, 50px) * 2);
		transform: translateY(calc(var(--parallax) * var(--parallax-range, 50px)));
	}

	[expand-wrapper] {
		display: block;
		width: 100%;
		height: 100%;
		transition: transform .7s;
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

@keyframes popup-in {
	from {
		opacity: 0;
		width: var(--source-width);
		height: var(--source-height);
		transform: translate(var(--source-x), var(--source-y));
	}
	to {
		opacity: 1;
		width: 100vw;
		height: 100vh;
		transform: translate(0px, 0px);
	}
}

@keyframes popup-img {
	0% {
		clip-path: polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%);
	}
	20% {
		clip-path: polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%);
	}
	100% {
		clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
	}
}

@keyframes popup-out {
	from {
		opacity: 1;
		width: 100vw;
		height: 100vh;
		transform: translate(0px, 0px);
	}
	to {
		opacity: 0;
		width: 100vw;
		height: 100vh;
		transform: translate(0px, 100px);
	}
}

#popup {
	position: fixed;
	background-color: #000;
	overflow: hidden;
	top: 0;
	left: 0;
	z-index: 10;

	&.ready:not(.open) {
		pointer-events: none;
		animation: popup-out .5s forwards;
	}

	&.open {
		animation: popup-in .7s forwards;
	}

	& > .close {
		display: inline-flex;
		position: absolute;
		align-items: center;
		justify-content: center;
		top: 48px;
		right: 42px;
		width: 30px;
		height: 22px;
		cursor: pointer;
		z-index: 1;
		color: #fff;

		&:hover {
			color: #C1974D;
		}

		&::after, &::before {
			position: absolute;
			transform-origin: center;
			width: 100%;
			height: 2px;
			background-color: currentColor;
			border-radius: 1px;
			top: 0;
			left: 0;
			content: "";
		}
		
		&::before {
			transform: translate(0px, 10px) rotate(45deg);
		}

		&::after {
			transform: translate(0px, 10px) rotate(-45deg);
		}
	}

	& > img {
		width: 100%;
		height: 100%;
		animation: popup-img 1s forwards;
		object-fit: contain;
	}
}