.pill {
	position: absolute;
	width: 100px;
	height: 300px;
	border-radius: 50px;

	&.outline {
		border: 1px solid #C79F6B;
		opacity: .25;
	}
}

img.pill {
	object-fit: cover;
}