@import "./partials/typography.scss";
@import "./partials/animations.scss";
@import "./partials/vectors.scss";
@import "./partials/nav.scss";
@import "./partials/buttons.scss";
@import "./elements/newsletter.scss";
@import "./elements/recentbuzz.scss";
@import "./elements/buzztile.scss";
@import "./elements/video.scss";
@import "./elements/instagram.scss";
@import "./elements/episode.scss";

.HomePage {
	background-color: #F2EFEB;
	overflow-x: hidden;
	margin: 0;

	#hero {
		position: relative;
		z-index: 0;
		overflow: hidden;
		width: 100%;
		margin: 0 auto;

		.image-viewport {
			position: relative;
			padding-top: #{m}in(160px, 13.6vw);
			margin: 0 auto;
			width: #{m}in(582px, calc(100vw - 72px));
			height: #{m}in(875px, 100vh);

			@media screen and (max-width: 800px) {
				height: #{m}in(420px, 100vh);
			}

			&.left .clip {
				right: 0;

				go-image {
					right: 0;
				}
			}

			&.right .clip {
				left: 0;

				go-image {
					left: 0;
				}
			}

			.clip {
				position: absolute;
				overflow: hidden;
				display: inline-block;
				width: 0%;
				height: #{m}in(875px, 100vh);
				z-index: 1;
				transition: width .5s;

				@media screen and (max-width: 800px) {
					height: #{m}in(420px, 100vh);
				}
			}

			.clip.current {
				width: 100%;
				z-index: 0;
				transition: none;
			}

			go-image {
				position: absolute;
				top: 0;
				width: #{m}in(582px, calc(100vw - 72px));
				height: 100%;
				object-fit: cover;
			}
		}

		.chris {
			position: absolute;
			top: 235px;
			z-index: 3;
			right: calc(50% - 512px);
			width: 360px;
			height: 830px;
			filter: drop-shadow(-10px 3px 12px rgba(0, 0, 0, 0.07));

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.arrows {
			display: flex;
			margin: 0 auto;
			width: 582px;
			margin-top: 15px;

			@media screen and (max-width: 800px) {
				position: absolute;
				top: calc(50% - 90px);
				margin: 0;
				width: 100%;
				box-sizing: border-box;
				padding: 0 10px;
				justify-content: space-between;
			}

			a {
				width: 8px;
				height: 16px;
				cursor: pointer;

				@media screen and (max-width: 800px) {
					width: 16px;
					height: 32px;
				}

				@media screen and (min-width: 801px) {
					&.prev {
						margin-right: 20px;
					}
				}
			}
		}

		.title {
			top: 0;
			left: 0;
			margin: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			position: absolute;
			font-size: #{m}in(246px, 21vw);
			font-family: quincy;
			font-weight: 300;
			line-height: 1em;
			z-index: 2;
			color: #C1974D;
			opacity: 0;
			transition: opacity 1.4s;

			&[animation-ready] {
				opacity: 1;
				
				span {
					transform: translateX(0);
				}
			}

			span {
				transform: translateX(calc(100% * var(--offset)));
				transition: transform .7s;
			}
		}

		span.pill.outline {
			top: 50%;
			left: 50%;
			z-index: -1;
			transform: translate(-50%, -50%) var(--transform) translateY(calc(var(--parallax) * 50%));

			@media screen and (max-width: 800px) {
				display: none;
			}

			&:nth-of-type(1) {
				width: 42px;
				height: 173px;
				border-radius: 21px;
				--transform: translate(-46vw, -26vh);
			}

			&:nth-of-type(2) {
				width: 82px;
				height: 396px;
				border-radius: 41px;
				--transform: translate(-50vw, 31vh);
			}

			&:nth-of-type(3) {
				width: 52px;
				height: 204px;
				border-radius: 26px;
				--transform: translate(-31vw, 24vh);
			}

			&:nth-of-type(4) {
				width: 56px;
				height: 193px;
				border-radius: 28px;
				--transform: translate(44vw, -35vh);
			}

			&:nth-of-type(5) {
				width: 78px;
				height: 253px;
				border-radius: 39px;
				--transform: translate(50vw, 12vh);
			}

			&:nth-of-type(6) {
				width: 52px;
				height: 236px;
				border-radius: 26px;
				--transform: translate(38vw, 58vh);
			}
		}

		img.pill {
			top: 50%;
			left: 50%;
			z-index: -1;
			transform: translate(-50%, -50%) var(--transform) translateY(calc(var(--parallax) * 50%));

			@media screen and (max-width: 800px) {
				display: none;
			}

			&:nth-of-type(1) {
				width: 106px;
				height: 281px;border-radius: 53px;
				--transform: translate(-38vw, -21vh);
			}

			&:nth-of-type(2) {
				width: 126px;
				height: 350px;
				border-radius: 63px;
				--transform: translate(-36vw, 47vh);
			}

			&:nth-of-type(3) {
				width: 98px;
				height: 324px;
				border-radius: 49px;
				--transform: translate(41vw, -26vh);
			}

			&:nth-of-type(4) {
				width: 120px;
				height: 354px;
				border-radius: 60px;
				--transform: translate(43vw, 54vh);
			}
		}

		.arrow {
			margin: 36px auto 55px auto;
			display: block;
			width: 12px;
			opacity: 0;
			transition: opacity 1.5s .5s;

			.stem {
				transform-origin: top;
				transform: scaleY(0.5);
				transition: transform 1.5s .5s;
			}

			.head {
				transform: translateY(-30px);
				transition: transform 1.5s .5s;
			}

			&[animation-ready] {
				opacity: 1;

				.stem, .head {
					transform: none;
				}
			}
		}
	}

	#mantra {
		position: relative;
		padding: 100px 50px 130px 50px;
		overflow: hidden;
		background-image: linear-gradient(to bottom, #F2EFEB, #F2EFEB 220px, #fff 520px, #fff);

		img.pill {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) translateY(calc(var(--parallax) * 50%));

			@media screen and (max-width: 800px) {
				display: none;
			}

			&:nth-child(1) {
				width: 134px;
				height: 427px;
				border-radius: 67px;
				top: -105px;
				left: 20%;
			}

			&:nth-child(2) {
				width: 134px;
				height: 378px;
				border-radius: 67px;
				top: 15%;
				left: 37px;
			}

			&:nth-child(3) {
				width: 168px;
				height: 498px;
				border-radius: 84px;
				top: 50%;
				left: 15%;
			}

			&:nth-child(4) {
				width: 122px;
				height: 361px;
				border-radius: 61px;
				top: 91%;
				left: 12%;
			}

			&:nth-child(5) {
				width: 114px;
				height: 350px;
				border-radius: 57px;
				top: 5%;
				left: 82%;
			}

			&:nth-child(6) {
				width: 178px;
				height: 498px;
				border-radius: 89px;
				top: 23%;
				left: 98%;
			}

			&:nth-of-type(7) {
				width: 130px;
				height: 398px;
				border-radius: 65px;
				top: 48%;
				left: 84%;
			}

			&:nth-of-type(8) {
				width: 112px;
				height: 326px;
				border-radius: 56px;
				top: 93%;
				left: 90%;
			}
		}

		.bee {
			display: block;
			margin: 0 auto;
			width: 195px;
			height: 143px;
			margin-bottom: 60px;
		}

		.top-content {
			@include default-fonts;
			margin: 60px 60px;
			text-align: center;
			max-width: 646px;
			margin: 0 auto;

			p {
				max-width: 520px;
				margin: 16px auto;
			}
		}

		.button {
			margin-top: 40px;
		}

		.arrow {
			display: block;
			width: 12px;
			margin: 60px auto 80px auto;
			opacity: 0;
			transition: opacity 1s;

			.stem {
				transform-origin: top;
				transform: scaleY(0.5);
				transition: transform 1s;
			}

			.head {
				transform: translateY(-30px);
				transition: transform 1s;
			}

			&[animation-ready] {
				opacity: 1;

				.stem, .head {
					transform: none;
				}
			}
		}

		.award-content {
			@include default-fonts;
			text-align: center;
			max-width: 710px;
			margin: 0 auto;
			margin-bottom: 30px;
		}

		.awards {
			display: grid;
			width: 700px;
			max-width: 100%;
			margin: 0 auto;
			justify-content: space-between;
			flex-wrap: wrap;
			column-gap: 16px;
			row-gap: 18px;
			grid-template-columns: repeat(var(--columns, 5), 1fr);
			grid-auto-flow: dense;
			justify-items: center;

			@media screen and (max-width: 1240px) {
				grid-template-columns: repeat(4, 1fr);
			}

			@media screen and (max-width: 650px) {
				grid-template-columns: repeat(2, 1fr);
			}

			img {
				filter: grayscale(1) opacity(0.8);
				height: 100px;
				max-width: 150px;
				object-fit: contain;

				@media screen and (max-width: 650px) {
					width: 100%;
					max-width: unset;
				}
			}
		}
	}

	#work {
		$grid-scale: 16;
		position: relative;
		padding: 80px 0 144px 0;
		display: grid;
		row-gap: 60px;
		grid-template-columns: repeat(14, 1fr);
		align-items: center;
		justify-items: center;
		background-image: linear-gradient(to bottom, #F2EFEB 0px, #F2EFEB 15%, #fff 40%, #fff);

		@media screen and (max-width: 1197px) {
			grid-template-columns: 50px repeat(12, 1fr) 50px;	
		}

		@media screen and (max-width: 1000px) {
			padding: 80px 36px 144px 36px;
			grid-template-columns: 1fr;
			grid-template-rows: repeat(auto-fit, auto);
		}

		.range {
			position: absolute;
			display: flex;
			grid-row: 1;
			top: 0;
			left: 0;
			width: 100%;
			box-sizing: border-box;
			padding: 0 140px;
			margin: 0 auto;
			align-items: center;

			@media screen and (max-width: 1150px) {
				padding: 0 36px;
			}

			@media screen and (max-width: 850px) {
				flex-direction: column;

				.right {
					margin-top: 12px;
				}
			}

			&[animation-ready] {
				.bar {
					width: 100%;
				}

				.from, .to {
					transform: translate(0);
					opacity: 1;
				}
			}

			.left, .right {
				display: flex;
				align-items: center;
				flex: 1;

				@media screen and (max-width: 850px) {
					width: 100%;
				}
			}

			.bar {
				width: 0%;
				height: 1px;
				background-color: #C1974D;
				
				&.seg1 {
					transition: width .5s .3s ease-in;

					@media screen and (max-width: 850px) {
						
					}
				}

				&.seg2 {
					transition: width .5s .8s ease-out;

					@media screen and (max-width: 850px) {
						grid-column: 1;
						grid-row: 2;
					}
				}
			}

			.from {
				margin-right: 50px;
				transform: translate(-50%);
				opacity: 0;
				min-width: max-content;
				transition: opacity 1s, transform 1s;

				@media screen and (max-width: 850px) {
					margin-right: 20px;
				}
			}

			.to {
				margin-left: 50px;
				transform: translate(-50%);
				opacity: 0;
				min-width: max-content;
				transition: opacity 1s 1.1s, transform 1s 1.1s;

				@media screen and (max-width: 850px) {
					margin-left: 20px;
				}
			}
		}

		h2 {
			font-family: quincy, serif;
			font-size: 53px;
			line-height: 60px;
			font-weight: 300;
			color: #C1974D;
			margin: 0;
		}

		h4 {
			font-family: quincy, serif;
			font-size: 31px;
			line-height: 32px;
			color: #C1974D;
			font-weight: 500;
		}

		.image1 {
			grid-row: 2 / 5;
			grid-column: 1 / 5;
			width: 100%;
			min-height: 48vw;

			@media screen and (max-width: 1000px) {
				height: 450px;
				width: 100%;
				grid-row: 3;
				grid-column: 1;
			}
		}

		.image2 {
			grid-row: 2 / 5;
			grid-column: 2 / 7;
			pointer-events: none;
			transform: translateY(60px);
			width: 100%;
			height: 100%;
			object-fit: contain;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.image3 {
			grid-row: 3;
			grid-column: 4 / 10;
			width: 100%;
			height: 22vw;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.heading1 {
			position: relative;
			grid-row: 3;
			grid-column: 11 / 14;
			width: 100%;

			@media screen and (max-width: 1000px) {
				grid-row: 4;
				grid-column: 1;
				font-size: 35px;
				line-height: 39px;
				text-align: center;
			}
		}

		.bee {
			grid-row: 3;
			grid-column: 14;
			width: 114px;
			height: 87px;
			object-fit: contain;
			justify-self: flex-start;
			transform: translate(-30px, 80px) rotate(0deg);

			&[animation-ready] {
				transform: translate(0, 0) rotate(20deg);
			}

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.residential-content {
			@include default-fonts;
			grid-row: 2;
			grid-column: 6 / 12;
			width: 100%;

			@media screen and (max-width: 1000px) {
				grid-row: 2;
				grid-column: 1;
				text-align: center;
				margin-top: 50px;
			}
		}

		.heading2 {
			grid-row: 4;
			grid-column: 7 / 10;
			text-align: right;
			margin-right: 60px;
			justify-self: flex-end;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.image4 {
			grid-row: 4 / 6;
			grid-column: 10 / 15;
			justify-self: flex-end;
			box-sizing: border-box;
			width: calc(100% - 60px);
			align-self: flex-start;
			height: 100%;
			width: 100%;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.image5 {
			grid-row: 5;
			grid-column: 2 / 10;
			width: calc(100% - 60px);
			margin-right: 60px;
			height: 30vw;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.image6 {
			grid-row: 8 / 9;
			grid-column: 5 / 9;
			justify-self: center;
			align-self: flex-end;
			height: calc((19vw - 30px) + 46px);
			width: calc((19vw - 30px) + 46px);

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.image7 {
			grid-row: 6 / 9;
			grid-column: 7 / 15;
			justify-self: flex-end;
			height: calc(38vw - 60px);
			margin-bottom: 46px;

			@media screen and (max-width: 1000px) {
				justify-self: center;
				height: 450px;
				width: 100%;
				grid-row: 6;
				grid-column: 1;
				margin: 0;
			}
		}

		.commercial-content {
			@include default-fonts;
			justify-self: flex-end;
			align-self: flex-start;
			grid-row: 6 / 8;
			grid-column: 2 / 7;
			margin-right: 86px;
			text-align: right;

			@media screen and (max-width: 1000px) {
				text-align: center;
				justify-self: center;
				grid-row: 5;
				grid-column: 1;
				margin: 0;
			}
		}

		.heading3 {
			grid-row: 6 / 9;
			grid-column: 10 / 14;
			padding-left: 60px;
			justify-self: flex-start;
			margin-top: 120px;

			@media screen and (max-width: 1000px) {
				grid-row: 7;
				grid-column: 1;
				text-align: center;
				justify-self: center;
				padding: 0;
				margin: 0;
				font-size: 35px;
				line-height: 39px;
			}
		}

		.image8 {
			grid-row: 9;
			grid-column: 1 / 9;
			width: 100%;
			height: 30vw;

			@media screen and (max-width: 1000px) {
				height: 450px;
				width: 100%;
				grid-row: 9;
				grid-column: 1;
			}
		}

		.showhouse-content {
			@include default-fonts;
			grid-row: 9;
			grid-column: 10 / 14;
			justify-self: flex-start;

			@media screen and (max-width: 1000px) {
				grid-row: 8;
				grid-column: 1;
				text-align: center;
				justify-self: center;
			}
		}

		.image9 {
			grid-row: 10 / 13;
			grid-column: 10 / 15;
			width: 100%;
			height: 51vw;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.image10 {
			grid-row: 10;
			grid-column: 3 / 10;
			align-self: flex-start;
			justify-self: flex-start;
			width: calc(100% - 60px);
			height: 30vw;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}

		.heading4 {
			grid-row: 11;
			grid-column: 3 / 10;
			justify-self: flex-start;
			align-self: flex-start;

			@media screen and (max-width: 1000px) {
				grid-row: 10;
				grid-column: 1;
				text-align: center;
				justify-self: center;
				font-size: 35px;
				line-height: 39px;
			}
		}

		.heading5 {
			grid-row: 8;
			grid-column: 2 / 5;
			text-align: right;

			@media screen and (max-width: 1000px) {
				display: none;
			}
		}
	}

	#tv {
		max-width: 1126px;
		width: calc(100% - 72px);
		box-sizing: content-box;
		background-color: #F2EFEB;
		margin: 0 auto;
		padding: 110px 36px 120px 36px;
		display: grid;
		column-gap: 30px;
		row-gap: 32px;
		grid-template-columns: 1fr auto;
		grid-template-areas:
			"heading image"
			"content image"
			"episodes episodes";

		@media screen and (max-width: 930px) {
			grid-template-columns: 1fr;
			justify-items: center;
			grid-template-areas:
				"heading"
				"image"
				"content"
				"episodes";
		}

		h1.heading {
			grid-area: heading;
			font-family: quincy, serif;
			font-size: 82px;
			line-height: 81px;
			font-weight: 300;
			color: #C1974D;
			margin: 0;

			@media screen and (max-width: 930px) {
				text-align: center;
				font-size: 59px;
				line-height: 58px;
			}
		}

		.content-wrapper {
			grid-area: content;

			@media screen and (max-width: 930px) {
				text-align: center;
			}

			.featured-on {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 44px;

				@media screen and (max-width: 930px) {
					flex-direction: column;
					justify-content: center;
				}

				span {
					font-family: quincy, serif;
					font-style: italic;
					width: max-content;
					color: #C1974D;
					font-size: 29px;
					margin-right: 29px;

					@media screen and (max-width: 930px) {
						display: block;
						width: 100%;
						margin-right: 0;
						margin-bottom: 12px;
					}
				}

				img {
					height: 45px;

					@media screen and (max-width: 930px) {
						height: 40px;
					}

					&:not(:last-child) {
						margin-right: 16px;

						@media screen and (max-width: 930px) {
							margin-right: 0;
							margin-bottom: 12px;
						}
					}
				}
			}

			.content {
				font-size: 14px;
				line-height: 26px;
				font-weight: 300;
				max-width: 362px;

				b, strong {
					font-weight: 500;
				}

				.button {
					margin-top: 45px;
				}

				@media screen and (max-width: 930px) {
					margin: 0 auto 45px auto;
				}
			}
		}

		.image {
			grid-area: image;
			width: 100%;
			max-width: 550px;
			height: 500px;

			img {
				object-position: top;
			}
		}

		.episodes-wrapper {
			margin-top: 93px;
			grid-area: episodes;

			.heading {
				margin-bottom: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				@media screen and (max-width: 930px) {
					justify-content: space-around;
				}

				h3 {
					margin: 0;
					font-size: 38px;
					font-family: quincy, serif;
					font-weight: 400;
					color: #C1974D;
					line-height: 48px;

					@media screen and (max-width: 930px) {
						font-size: 30px;
					}
				}

				a {
					color: #3C3C3C;
					text-decoration: none;

					@media screen and (max-width: 930px) {
						display: none;
					}

					&:hover {
						color: #C1974D;
						text-decoration: underline;
					}
				}
			}

			.episodes {
				display: flex;

				@media screen and (max-width: 930px) {
					flex-direction: column;
				}

				.episode {
					@media screen and (max-width: 930px) {
						&:not(:first-child) {
							margin-top: 16px;
						}
					}

					@media screen and (min-width: 931px) {
						&:not(:last-child) {
							margin-right: 16px;
						}
					}
				}
			}

			.mobile-link {
				display: none;
				color: #C1974D;
				text-decoration: underline;
				text-align: center;
				margin-top: 35px;

				@media screen and (max-width: 930px) {
					display: block;
				}
			}
		}
	}

	#gallery {
		padding-top: 94px;
		padding-bottom: 110px;
		background-color: #fff;
		overflow: hidden;

		.heading-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 45px;

			&[animation-ready] {
				&::before, &::after {
					width: 130px;

					@media screen and (max-width: 960px) {
						width: 50px;
					}
				}

				.heading span {
					opacity: 1;
					transform: translateY(0px);
				}
			}

			&::before, &::after {
				height: 0px;
				width: 0px;
				height: 2px;
				background-color: #C1974D;
				content: "";
				transition: width 1s calc(var(--offset) * 0.5s);
			}

			.heading {
				font-family: quincy, serif;
				font-size: 68px;
				line-height: 68px;
				font-weight: 300;
				text-align: center;
				color: #C1974D;
				margin: 0;
				
				@media screen and (max-width: 960px) {
					font-size: 30px;
					line-height: 30px;
				}

				span {
					display: inline-block;
					opacity: 0;
					transform: translateY(40px);
					transition: transform 1s calc(var(--offset) * 0.5s), opacity 1s calc(var(--offset) * 0.5s);
				}
			}
		}

		.carousel {
			position: relative;
			display: block;
			height: 515px;
			width: 100%;
			perspective-origin: center;
			perspective: 1500px;

			@media screen and (max-width: 800px) {
				height: 420px;
			}

			&::after {
				position: absolute;
				pointer-events: none;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-image: linear-gradient(to right, rgba(255, 255, 255, 1) calc((100% - 1990px) / 2), rgba(255, 255, 255, 0) calc((100% - 1990px) / 2 + 120px), rgba(255, 255, 255, 0) calc(100% - (100% - 1990px) / 2 - 120px), rgba(255, 255, 255, 1) calc(100% - (100% - 1990px) / 2));
				content: "";
			}

			&.processed .image-wrapper {
				opacity: 0.5;
				transform: translateX(calc(var(--sign) * 172px)) rotateY(calc(30deg * var(--rotation))) translateX(calc(var(--offset-x) * 100% - var(--sign) * 172px + var(--offset-x) * 35px));
			}

			&:not(.active-animation).processed .image-wrapper {
				transition: transform 1s, opacity 1s;
			}

			&.active-animation .image-wrapper {
				transition: transform .5s, opacity .5s;
			}

			.image-wrapper {
				position: absolute;
				display: block;
				left: calc(50% - #{m}in(400px, calc(100% - 130px)) / 2);
				opacity: 0;
				width: #{m}in(400px, calc(100% - 130px));
				height: 515px;
				overflow: hidden;
				transform: translate(0);

				@media screen and (max-width: 800px) {
					height: 420px;
				}

				&.focus {
					opacity: 1;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transition: transform .3s;
				}
			}
		}

		.cta {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding: 0 36px;

			.text {
				font-size: 38px;
				font-family: quincy, serif;
				color: #C1974D;
				margin: 75px 0 28px 0;
			}
		}
	}
}